<script>
import AiCommonSettings from '../components/ai_common_settings.vue';

export default {
  name: 'AiGroupSettings',
  components: {
    AiCommonSettings,
  },
};
</script>
<template>
  <ai-common-settings :large-title="false" />
</template>
